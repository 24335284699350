// 移动端消息页面
<template>
  <div class="m_total" id="mtotal">
    <!-- 置顶top -->
    <Mtop></Mtop>
    <div v-show="iskefu && $store.state.mhome">
      <!-- 联系客服 -->
      <transition name="mcenter_3">
        <div class="mmess_title" v-show="enter">
          <a>常见问题</a>
          <div class="mmess_right" @click="clilianxi('faq')">
            <img src="../../assets/img/mmess_happy@2x.png" alt="返回首页" />
            <a>联系客服</a>
          </div>
        </div>
      </transition>
      <!-- 问题的标签tag头 -->
      <div class="mmess">
        <a
          v-for="(item, index) in faq_category_list"
          :key="index"
          class="m_mmess_tag-list"
          :class="{ 'm_mmess_tag-list-act': tagindex == index }"
          @click="helptagChange(0, index, item.id)"
        >
          {{ item.name }}
        </a>
      </div>
      <!-- 问题主体内容 -->
      <transition name="mcenter_3">
        <div class="mmess_body" v-show="enter">
          <div class="mmess_list" v-for="(item, index) in faq_list" :key="index">
            <div class="mmess_list_top">
              <div class="mmess_list-img"></div>
              <a>{{ item.title }}</a>
            </div>
            <div class="mmess_list_bottom">
              <p>{{ item.content }}</p>
            </div>
          </div>
          <div class="mann_none" v-show="!faq_list">
            <img src="../../assets/img/mann_none@2x.png" alt="没有消息提示内容" />
            <a>暂时没有内容</a>
          </div>
        </div>
      </transition>
      <!-- 广告合作 -->
      <div class="mmess_block"></div>
      <!-- <div class="mguanggaohz" @click="clilianxi('advertising')">
        <a>广告合作</a>
      </div> -->
    </div>
    <!-- <div v-show="!iskefu">
      <div class="mkefu">
        <img src="../../assets/img/left2.png" @click="iskefu = true" />
        <a>在线联系</a>
      </div>
      <div class="mkefu_body">
        <div class="mkefu_erwm" v-if="customer_service_list.wechat_qrcode">
          <img :src="customer_service_list.wechat_qrcode" />
        </div>
        <a class="mkefu_nodes">截图微信扫码联系客服</a>
      </div>
    </div> -->
    <!-- WIN 联系客服 -->
    <transition name="mcenter_2">
      <div class="mpayresult" v-show="custok">
        <div class="callcustomer">
          <img
            v-if="customer_service_list"
            :src="customer_service_list.wechat_qrcode"
            alt="客服联系方式的微信二维码"
          />
          <span class="cust01">截图保存打开微信扫码添加</span>
          <span class="cust02">客服在线时间：{{ timeText }}</span>
          <span class="cust02">{{ timeArea }}</span>
        </div>
        <img
          class="login-close"
          @click="openandclo('custok', false)"
          src="../../assets/img/close.png"
          alt="关闭联系客服弹窗"
        />
      </div>
    </transition>
    <!-- 遮罩层 -->
    <div id="coverm"></div>
    <Mbottom></Mbottom>
  </div>
</template>
<script>
let that;
let fetch;
let vuex;
import Mtop from '../../components/moblie/mTop';
import Mbottom from '../../components/moblie/mBottom';
export default {
  components: {
    Mtop,
    Mbottom,
  },
  name: 'mMess',
  data() {
    return {
      timeText: '', //客服在线时间
      timeArea: '', //客服时间时区
      custok: false, //联系客服弹窗
      symbol: '',
      customer_service_list: [],
      enter: false,
      iskefu: true,
      faq_category_list: [],
      faq_list: [],
      tagindex: 0,
    };
  },
  async created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;
    that;
    fetch;
    vuex;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (!that.YS.ismob()) {
      //web端地址
      window.location.href = window.g.WEB_URL + '/help.html';
    }
    let data = await fetch.api_category({});
    try {
      if (data && data.faq_category_list) {
        that.faq_category_list = data.faq_category_list;
        let data1 = await fetch.api_categoryid({
          category_id: data.faq_category_list[0].id,
        });
        try {
          if (data && data.faq_category_list && data.faq_category_list[0]) {
            that.faq_list = data1.faq_list;
          }
        } catch (error) {
          console.log(error.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  },
  mounted() {
    that.enter = true;

    // setTimeout(() => {
    //   that.YS.actgo('m_mmess_tag-list', 'm_mmess_tag-list-act', 0);
    // }, 300);
    if (that.$route.query.kefu) {
      that.symbol = that.$route.query.kefu;
      that.customerService();
      that.iskefu = false;
    }
    try {
      let timeCha = -(new Date().getTimezoneOffset() / 60);
      // debugger;
      let stratTime = that.$store.state.onlineTime.startTime + timeCha;
      let endTime = that.$store.state.onlineTime.endTime + timeCha;
      let countNum = 0;
      while (stratTime < 0 && countNum < 10) {
        stratTime += 24;
        countNum += 1;
      }
      while (stratTime >= 24 && countNum < 10) {
        stratTime -= 24;
        countNum += 1;
      }
      while (endTime < 0 && countNum < 10) {
        endTime += 24;
        countNum += 1;
      }
      while (endTime > 24 && countNum < 10) {
        endTime -= 24;
        countNum += 1;
      }
      let string_stratTime = stratTime.toString();
      let string_endTime = endTime.toString();
      if (string_stratTime.length < 2) string_stratTime = '0' + string_stratTime;
      if (string_endTime.length < 2) string_endTime = '0' + string_endTime;
      that.timeText = string_stratTime + ':00-' + string_endTime + ':00';
    } catch (error) {
      console.log(error.message);
    }
    try {
      let linshiArea = new Date().toString().split('(')[1].split(')')[0];
      that.timeArea = linshiArea;
    } catch (error) {
      console.log(error.message);
    }
  },
  methods: {
    //开关窗口
    openandclo(type, boo) {
      that[type] = boo;
      var coverm = document.querySelector('#coverm');
      if (boo) {
        coverm.style.display = 'block';
      } else {
        coverm.style.display = 'none';
      }
    },
    // 点击帮助标题
    helptagChange(e, i, id) {
      fetch.api_categoryid({ category_id: id }).then((data) => {
        // that.YS.actgo('m_mmess_tag-list', 'm_mmess_tag-list-act', i);
        that.tagindex = i;
        that.faq_list = data.faq_list;
      });
    },
    //获取客服信息
    customerService() {
      fetch.api_customerServiceinfo({ symbol: that.symbol }).then((data) => {
        that.customer_service_list = data.customer_service_list;
      });
    },
    //点击联系客服
    clilianxi(type) {
      //替换帮助中心的标识
      that.symbol = type;
      that.customerService();
      that.openandclo('custok', true);
      // that.iskefu = false;
    },
  },
};
</script>
<style lang="scss" scope></style>
